import React, { createContext, useState, useEffect } from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { useServerData } from '../../state/serverDataContext';

const LoadingPlaceholder = () => <></>;

const BannerAT = Loadable({
  loader: () => import(/* webpackChunkName: 'BannerAT' */ './locales/BannerAT'),
  loading: () => <></>,
});
const BannerWithStepsHeadings = Loadable({
  loader: () => import(/* webpackChunkName: 'BannerWithStepsHeadings' */ './locales/BannerWithStepsHeadings'),
  loading: () => <></>,
});
const BannerUK = Loadable({
  loader: () => import(/* webpackChunkName: 'BannerUK' */ './locales/BannerUK'),
  loading: () => <></>,
});
const BannerDefault = Loadable({
  loader: () => import(/* webpackChunkName: 'BannerDefault' */ './locales/BannerDefault'),
  loading: () => <></>,
});

export const BannerPropTypes = {
  sliderData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export const LocaleContext = createContext({ dataJurisdiction: '', headlineAsSpan: false });

const withLoadingPlaceholder = (Component, Placeholder) => {
  return function WithLoadingPlaceholder(props) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      setLoaded(true);
    }, []);

    return loaded ? <Component {...props} /> : <Placeholder />;
  };
};

const Banner = () => {
  const { sliderData, domain, locale, isMobile } = useServerData(data => ({
    sliderData: data.pageData?.sliderData || [],
    domain: data.domain,
    locale: data.locale,
    isMobile: data.isMobile || false,
  }));

  const localeContextValues = {
    'es-es': { dataJurisdiction: '', headlineAsSpan: true },
    'it-it': { dataJurisdiction: 'it-it', headlineAsSpan: true },
    'en-mt': { dataJurisdiction: 'en-mt', headlineAsSpan: true },
    'en-nz': { dataJurisdiction: 'en-nz', headlineAsSpan: true },
    'en-is': { dataJurisdiction: 'en-is', headlineAsSpan: true },
    'en-si': { dataJurisdiction: 'en-si', headlineAsSpan: true },
    'en-lu': { dataJurisdiction: 'en-lu', headlineAsSpan: true },
    'en-mu': { dataJurisdiction: 'en-mu', headlineAsSpan: true },
    'en-ca': { dataJurisdiction: '', headlineAsSpan: false },
    'de-at': { dataJurisdiction: 'de-at', headlineAsSpan: true },
    'en-uk': { dataJurisdiction: 'en-uk', headlineAsSpan: true },
    'en-ir': { dataJurisdiction: 'en-ir', headlineAsSpan: true },
  }[locale] || { dataJurisdiction: '', headlineAsSpan: false };

  const isAtDomain = domain && domain === 'at';

  const LoadableBannerAT = withLoadingPlaceholder(BannerAT, LoadingPlaceholder);
  const LoadableBannerWithStepsHeadings = withLoadingPlaceholder(BannerWithStepsHeadings, LoadingPlaceholder);
  const LoadableBannerUK = withLoadingPlaceholder(BannerUK, LoadingPlaceholder);
  const LoadableBannerDefault = withLoadingPlaceholder(BannerDefault, LoadingPlaceholder);

  const LocaleBanner =
    {
      'es-es': LoadableBannerDefault,
      'it-it': LoadableBannerWithStepsHeadings,
      'en-mt': LoadableBannerWithStepsHeadings,
      'en-nz': LoadableBannerWithStepsHeadings,
      'en-is': LoadableBannerWithStepsHeadings,
      'en-si': LoadableBannerWithStepsHeadings,
      'en-lu': LoadableBannerWithStepsHeadings,
      'en-mu': LoadableBannerWithStepsHeadings,
      'en-uk': LoadableBannerUK,
      'en-ir': LoadableBannerUK,
      'de-at': isAtDomain ? LoadableBannerAT : LoadableBannerDefault,
    }[locale] || LoadableBannerDefault;

  return (
    <LocaleContext.Provider value={localeContextValues}>
      <LocaleBanner sliderData={sliderData} isMobile={isMobile} />
    </LocaleContext.Provider>
  );
};

export default Banner;
